import { StateCreator } from "zustand";
import { sliceResetFns } from "./resetStores";

export const mapPlatformNames: Record<string, string> = {
  "glass-door": "Glassdoor",
  "team-blind": "Team Blind",
  indeed: "Indeed",
};

export interface RatingsData {
  platform: string;
  monthlyAggregatedData: { rating: string; month: string }[];
}
export interface GeographyData {
  city: string;
  state: string;
  country: string;
  coordinates: number[];
  reviewCount: number;
}

interface ReviewSiteFiltersType {
  ratingsOption: string;
  reviewsByDepartmentOption: string;
  reviewsByGeographyOption: string;
}

interface State {
  ratingsData: RatingsData[];
  geographyData: GeographyData[];
  loadingRatingsData: boolean;
  initializedRatingsData: boolean;
  loadingGeographyData: boolean;
  initializedGeographyData: boolean;
  reviewSiteFilters: ReviewSiteFiltersType;
}

export interface ReviewSiteSlice extends State {
  setRatingsData: (RatingsData: RatingsData[]) => void;
  setLoadingRatingsData: (loading: boolean) => void;
  setLoadingGeographyData: (loading: boolean) => void;
  setReviewSiteFilters: (
    item: string,
    value: string | boolean | number
  ) => void;
  setGeographyData: (GeographyData: GeographyData[]) => void;
}

const INITIAL_STATE: State = {
  ratingsData: [],
  geographyData: [],
  loadingRatingsData: false,
  loadingGeographyData: false,
  initializedRatingsData: false,
  initializedGeographyData: false,
  reviewSiteFilters: {
    ratingsOption: "overall_rating",
    reviewsByDepartmentOption: "glass-door",
    reviewsByGeographyOption: "glass-door",
  },
};

export const createReviewSiteSlice: StateCreator<
  ReviewSiteSlice,
  [],
  [],
  ReviewSiteSlice
> = (set, get) => {
  sliceResetFns.add(() => set(INITIAL_STATE));
  return {
    ...INITIAL_STATE,
    setRatingsData: (data) => {
      set({ ratingsData: data, initializedRatingsData: true });
    },
    setLoadingRatingsData: (loading) => set({ loadingRatingsData: loading }),
    setLoadingGeographyData: (loading) =>
      set({ loadingGeographyData: loading }),
    setReviewSiteFilters: (item, value) => {
      return set({
        reviewSiteFilters: { ...get().reviewSiteFilters, [item]: value },
      });
    },
    setGeographyData: (data) => {
      set({ geographyData: data, initializedGeographyData: true });
    },
  };
};
