import { StateCreator } from "zustand";
import { sliceResetFns } from "./resetStores";
import { PostProcessingStatus } from "@squeeze-io/database";

export type CustomPostProcessingStatusList = {
  company: string;
  status: PostProcessingStatus;
  updatedAt: Date;
  createdAt: Date;
};

interface State {
  postProcessingStatusList: CustomPostProcessingStatusList[];
  initializedPostProcessingStatusList: boolean;
}

export interface PostProcessingStatusListSlice extends State {
  setPostProcessingStatusList: (
    value: CustomPostProcessingStatusList[]
  ) => void;
  getPostProcessingStatusList: () => CustomPostProcessingStatusList[];
}

export const INITIAL_STATE: State = {
  postProcessingStatusList: [],
  initializedPostProcessingStatusList: false,
};

export const createPostProcessingStatusSlice: StateCreator<
  PostProcessingStatusListSlice,
  [],
  [],
  PostProcessingStatusListSlice
> = (set, get) => {
  sliceResetFns.add(() => set(INITIAL_STATE));
  return {
    ...INITIAL_STATE,
    setPostProcessingStatusList: (data) => {
      return set({
        postProcessingStatusList: data,
      });
    },
    getPostProcessingStatusList: () => {
      return get().postProcessingStatusList;
    },
  };
};
